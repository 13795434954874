<template>
  <SVGLogoRender
    :logo="fileName"
    :svg-width="width"
    no-margin
    :left="left"
    :pointer="pointer"
    :width-auto="widthAuto"
    alt="Tether Logo"
    @logoClick="interact"
  />
</template>

<script>
import SVGLogoRender from '@/components/atoms/logos/SvgLogo.vue';

export default {
  name: 'TetherLogo',
  components: {
    SVGLogoRender,
  },
  props: {
    typeOfStyle: {
      type: String,
      validator(value) {
        return ['white', 'flat', 'degrade'].includes(value);
      },
      default: 'degrade',
    },
    alignment: {
      type: String,
      validator(value) {
        return ['horizontal', 'vertical', 'isotype'].includes(value);
      },
      default: 'horizontal',
    },
    otherFormats: {
      type: String,
      validator(value) {
        return ['textWhite', 'base'].includes(value);
      },
      default: 'base',
    },
    // Other props that will be propagated to the SVG render component
    width: {
      type: String || Number,
      default: 60,
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileName() {
      return `tether/${this.typeOfStyle}-${this.alignment}-${this.otherFormats}.svg`;
    },
  },
  methods: {
    interact() {
      this.$emit('click');
    },
  },
};
</script>
