<template>
  <section class="d-flex fill-width justify-star">
    <div v-for="(breadcrumb, index) in breadcrumbArray" :key="index" class="d-flex">
      <SvgIcon
        v-if="index !== 0"
        :icon="'horizontal-expand.svg'"
        size="24"
        style="margin: 0 8px 0 8px "
      />
      <PrimaryText
        style="cursor: pointer"
        :text="breadcrumb.text"
        :font-family="'poppins'"
        bold
        big
        width-auto
        :disabled="index !== breadcrumbArray.length - 1"
        @click="goToPath(breadcrumb)"
      />
    </div>
  </section>
</template>

<script>
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'BreadcrumbsBar',
  components: {
    PrimaryText,
    SvgIcon,
  },
  props: {
    breadcrumbArray: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    goToPath(breadcrumb) {
      if (this.currentRoute !== breadcrumb.route) {
        this.$router.push(breadcrumb.route);
      }
      if (breadcrumb.callback && typeof breadcrumb.callback === 'function') {
        breadcrumb.callback();
      }
    },
  },
};
</script>
